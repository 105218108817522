var $orbitContainer = jQuery('.orbit-container');
var highest, timeout;

if ($orbitContainer) {
    carouselState();
}

function resizeCarousel(carousel) {
    var $carousel = jQuery(carousel);
    highest = 0;
    
    $carousel.find('.orbit-slide').each(function() {
        var thisHeight = jQuery(this).outerHeight();

        if (thisHeight > highest) { 
            highest = thisHeight; 
        }
    });
    
    $carousel.css('height', highest + 'px');
}

function carouselState() {
    // Check and adjust size on resize
    jQuery(window).on('resize', function() {
        $orbitContainer.each(function() {
            clearTimeout(timeout);
            
            timeout = setTimeout(function() {
                resizeCarousel(this);
            }, 300);
        })
    });

    //Check and adjust size after load to make sure is set
    jQuery(document).ready(function() {
        $orbitContainer.each(function() {
            var self = this;
            timeout = setTimeout(function() {
                resizeCarousel(self);
            }, 2000);

        })
    });
}