jQuery(function($) {

	/* 
	 * *******************************************************************************
	 * INIT FOUNDATION
	 * *******************************************************************************
	 */
	 $(document).foundation();

	/* 
	 * *******************************************************************************
	 * IMPORT JS FILES
	 * *******************************************************************************
	 */
	 // @codekit-append "../../template-parts/blocks/divider/divider.js";
	 // @codekit-append "../../template-parts/blocks/pricing/pricing-tabs.js";
	 // @codekit-append "../../template-parts/footer/nav-main.js";
	 // @codekit-append "../../template-parts/blocks/content/sticky-sidebar.js";
	 // @codekit-append "../../template-parts/blocks/content/smooth-anchor.js";
	 // @codekit-append "../../template-parts/blocks/carousel/carousel-orbit.js";
	 // @codekit-append "waypoints.js";
	 // @codekit-append './partials/filter-case-studies';

	/* 
	 * *******************************************************************************
	 * GLOBAL JS
	 * *******************************************************************************
	 */	
	 
	 function tyk_scroll_to_hash() {

	 	if( window.location.hash ) {
	 		var hash = window.location.hash;
	 		// var target_padding = parseInt( $(hash).css( 'padding-top' ) );
	 		// var target_parent_padding = parseInt( $(hash).parent( '.row' ).css( 'padding-top' ) );

			if (!$(hash).length) return;

			setTimeout(function() {
				$('html, body').animate({
					scrollTop: $(hash).offset().top - 120
				}, 'fast', 'swing');
			}, 400);
	 	}
	}

	function cookieBannerCustomClose() {
		jQuery('#cookie_action_reject_x').on('click', function(e) {
			e.preventDefault();
			jQuery('#cookie_action_close_header_reject').trigger('click');
		});
	}

	

	/* DOCUMENT LOAD
	 * Load the following functions on document load
	 */
	 $(document).ready(function() {
	 	tyk_scroll_to_hash();
		 cookieBannerCustomClose();
	 });

	 $(window).bind("load", function() {
		// code here
		tyk_scroll_to_hash();
	 });

});