(function($) {

    const $scheduling_block = $('.scheduling--multiple');
    const $selector = $scheduling_block.find('#schedulingSelector');
    let calendar;

    if (!$selector.length) return;

    $selector.on('change', (e) => {
        calendar = $(e.currentTarget).val();    
        toggleCalendar();
    });

    function toggleCalendar() {
        $scheduling_block.find(`.scheduling__calendar`).removeClass('active');
        
        if (calendar) {
            $scheduling_block.find(`.scheduling__calendar[data-calendar="${calendar}"]`).addClass('active');
        }
    }

})(jQuery);