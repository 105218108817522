(function($) {

    const $code = $('code');
    const $pre = $('pre');
    let $copyButton;
    
    const wrapPreInner = async function() {
        await $pre.each($i => {
            const $currentPre = $($pre[$i]);
            
            if (!$currentPre.find('code').length) {
                $currentPre.wrapInner('<code></code>');
            }
        })
    }

    const setButtons = async function() {
        await wrapPreInner();
        await $pre.prepend('<button class="button button--secondary copy-clipboard"><span>Copy to clipboard</span><i></i></button><p></p>');
    } 

    const copyToClipboard = (el) => {
        const codeToCopy = $(el).closest('pre').find('code');
        const copyText = codeToCopy.text();
        navigator.clipboard.writeText(copyText);
    }
    
    const copyConfirmation = function(el) {
        const $button = $(el);
        const buttonText = $button.text();

        $button.find('span').text('Copied!');
        $button.addClass('js-copied');

        setTimeout(() => {
            $button.find('span').text(buttonText);
            $button.removeClass('js-copied');
        }, 1500);
    }
    
    setButtons().then(function() {
        $copyButton = $('pre').find('.copy-clipboard');

        $copyButton.on('click', function(e) {
            e.preventDefault();

            copyToClipboard(this);
            copyConfirmation(this);
        });
    });
    

})(jQuery);
