const $sivarFilter = jQuery('#sivar_partners_filter');
const $sivarPartners = jQuery('.partners-sivar');
const $sivarRegion = jQuery('#filter_partner_region');
const $sivarType = $sivarFilter.find('.button--order');
let currentType = 'all';
let currentRegion = 'all';

const sivarFiltering = {
    notFoundMessage(action) {
        jQuery('.no-filtered-found').remove();
    
        if (action === 'add') {
            jQuery('.cards.partners .grid-x').append('<h3 class="col no-filtered-found" style="margin-top: 20%;">No partners found for your search</h3>');
        }
    },

    filterByRegion() {
        const self = this;

        $sivarRegion.on('change', (e) => {
            currentRegion = e.currentTarget.value;
            self.filterCards();
        });
    },

    filterByType() {
        const self = this;
        
        $sivarType.on('click', (e) => {
            const $button = jQuery(e.currentTarget);
            const isActive = $button.hasClass('active');

            if (isActive) {
                $button.removeClass('active');
                currentType = 'all';
            } else {
                $sivarType.removeClass('active');
                $button.addClass('active');
                currentType = $button.data('type');
            }

            self.filterCards();
        });
    },

    filterCards() {
        // Show evertying if no filter added
        if (currentType === 'all' && currentRegion === 'all') {
            $sivarPartners.show();
            return;
        }

        // Filter cards as per filtering options
        const $cards = $sivarPartners.filter((i) => {
            const $card = jQuery($sivarPartners[i]);
            
            if (currentType === 'all') return $card.data('region') == currentRegion;
            if (currentRegion === 'all') return $card.data('type') == currentType;

            return $card.data('type') == currentType && $card.data('region') == currentRegion;
        });
        
        // Hide all cards
        $sivarPartners.hide();
        
        // Show only filtered cards or nothing found message
        if ($cards.length) {
            this.notFoundMessage('remove');
            $cards.show();
        } else {
            this.notFoundMessage('add')
        }
    }
    
}

if ( $sivarRegion ) {
    sivarFiltering.filterByRegion();
}

if ( $sivarType ) {
    sivarFiltering.filterByType();
}