const $listingFilter = jQuery('.listing-filter-js');
const $filtered = jQuery('.listing-filtered-content');
let $items;
let $nolisting;
let $select;
let $showAll;

if ($listingFilter.length) {
    setFilter();
}

function setFilter() {
    $select = $listingFilter.find('select');
    $showAll = $listingFilter.find('#filter_showall');
    
    $items = $filtered.find('.filtered-item');
    $nolisting = $filtered.find('.no-listing');

    checkLocationParams();

    // 1. Listen filter change event
    $select.on('change', function(e) {
        filterActioned(e);
    });

    // 2. Listen when all is clicked
    $showAll.on('click', function(e) {
        e.preventDefault();
        showAll();
    });
}

function checkLocationParams() {
    const urlParams =  new URLSearchParams(window.location.search);
    let currentParam;
    let filterParams = [];
    jQuery.each($select, function(i, input) {
        filterParams.push(jQuery(input).attr('name'));
    });
    
    if (!filterParams.length) return;
    
    for(let i = 0; i < filterParams.length; i++) {
        currentParam = urlParams.get(filterParams[i]);

        if (currentParam) {
            filterListing(null, filterParams[i], currentParam);
            setFilterParam(filterParams[i], currentParam)
            scrollFilterToView(); 
            return;
        }
    }
}

function setFilterParam(filterName, paramValue) {
    const $filterOptions = $listingFilter.find(`select[name="${filterName}"]`).children();
    
    // Create a list with available values for the specific filter input
    const $filterAvailableOptions = [];
    jQuery.each($filterOptions, function(i, option) {
        $filterAvailableOptions.push(jQuery(option).val());
    });

    // Set filter to correct value if exists otherwise set to all
    if ($filterAvailableOptions.includes(paramValue)) {
        $listingFilter.find(`select[name="${filterName}"]`).val(paramValue);
    } else {
        $listingFilter.find(`select[name="${filterName}"]`).val('all');
    }
}

function filterActioned(e) {
    // show only items depending on filter values or url parameters
    const $filter = jQuery(e.currentTarget);
    const filterBy = $filter.attr('name');
    const filterValue = $filter.val();

    filterListing($filter, filterBy, filterValue);
    scrollFilterToView();
    setFilteresToInital(filterBy);
}

function showAll() {
    // show all items
    $items.show();
    noCasesMessage('remove');
    scrollFilterToView();
    setFilteresToInital();
    setUrlParameters();
}

function filterListing($filter, filterBy, filterValue) {
    if (filterValue === 'all') {
        $items.show();
        noCasesMessage('remove');
        setUrlParameters();
        return;
    }

    const $toShow = $filtered.find(`.filtered-item[data-${filterBy}*=${filterValue}]`);
    const $toHide = $filtered.find(`.filtered-item:not([data-${filterBy}*=${filterValue}])`);

    setUrlParameters(filterBy, filterValue);

    if ($toShow.length) {
        $items.show();
        $toHide.hide();
        noCasesMessage('remove');
    } else {
        $items.hide();
        noCasesMessage('add');
    }
}

function setUrlParameters(filterBy, filterValue) {
    // Set the parameters in url so it could be copied and shared specific filetered search
    if (!filterBy, !filterValue) {
        window.history.replaceState(null, null, `${location.origin}${location.pathname}`);
    } else {
        window.history.replaceState(null, null, `?${filterBy}=${filterValue}`);
    }
}

function setFilteresToInital(filterBy) {
    // Reset other filters apart from the one last used
    var $filtersNoSelected = jQuery('.filter__select').find(`select:not([name=${filterBy}])`);
    $filtersNoSelected.val('all');
}

function noCasesMessage(action) {
    if (!$nolisting.length) return;
    
    // Show hide the no content message if there is no content to show
    if (action === 'add') {
        $nolisting.show();
    } else {
        $nolisting.hide();
    }
}

function scrollFilterToView() {
    // When the filter is used, sroll to locate filter and filtered content on view
    const listingContentOffset = $listingFilter.offset().top;
    const headerHeight = jQuery('#masthead').height();

    jQuery('html, body').animate({
        scrollTop: listingContentOffset - headerHeight * 2
    }, 650);
}


// 5. If page loaded and has parameters
//  - Toggle active listing items (values sent as parameters)
//  - Select active on filter :
//      - Find filter by type
//      - Add value depending on parameter value
//  -Scroll to listing section

