/**
 * SVG POSITION
 * Gets the height of the svg and positions it correctly
 */
function tyk_divider_svg_pos( target_divider ) {

	/* Get the height of the svg */
	const target_svg_height = Math.floor( jQuery( target_divider + ' svg' ).height() - 1 );

	/* Set the height of the divider to match the svg height */
	jQuery( target_divider ).height( target_svg_height );

	/* Position the svg */
	jQuery(target_divider).css( 'margin-top', '-' + (target_svg_height - 1) + 'px' );

	/* Define the previous and next siblings */
	const target_prev = jQuery( target_divider ).prevAll( '.row' ).first();
	const target_next = jQuery( target_divider ).nextAll( '.row' ).first();

	/* Get the bottom padding of the previous sibling */
	const target_prev_inner = jQuery( target_prev ).children( '.row__inner' );
	jQuery( target_prev_inner ).css('padding-bottom', '');
	const target_prev_padding_bottom = parseInt( target_prev_inner.css( 'padding-bottom' ) );

	/* Set the offset value for each type of row */
	let target_prev_adjust_offset, target_next_adjust_offset;

	if ( target_prev.hasClass( 'hero' ) ) {

		if( target_next.hasClass( 'cards' ) ) {
			target_prev_adjust_offset = 2;
			target_next_adjust_offset = 2;
		}
		else if( target_next.hasClass( 'pricing-section' )) {
			if (Foundation.MediaQuery.atLeast('large')) { 
				target_prev_adjust_offset = 2.5;
				target_next_adjust_offset = 2.5;
			} else {
				target_prev_adjust_offset = 1;
				target_next_adjust_offset = 1;
			}
		}
		else if( target_next.hasClass( 'shiftup-container' )) {
			target_prev_adjust_offset = 1;
			target_next_adjust_offset = 1;
		}
		else {
			target_prev_adjust_offset = 0.5;
		}
	}
	else {

		if( target_next.hasClass( 'cards' ) ) {
			target_prev_adjust_offset = 2;
			target_next_adjust_offset = 2;
		}
		else if( target_next.hasClass( 'shiftup-container' )) {
			target_prev_adjust_offset = 1.5;
			target_next_adjust_offset = 2;
		}
		else {
			target_prev_adjust_offset = 1;
		}
	}	

	/* Position the divider */
	const target_prev_padding_bottom_adjust = Math.floor( target_prev_padding_bottom + ( target_svg_height * target_prev_adjust_offset ) ); // set how much bottom padding to add
	jQuery( target_prev_inner ).css( 'padding-bottom', target_prev_padding_bottom_adjust + 'px' );

	/* Position the cards */
	if (Foundation.MediaQuery.atLeast('large')) {
		const cards_adjust = Math.floor( target_prev_padding_bottom + ( target_svg_height * target_next_adjust_offset ) );
		jQuery( '.divider + .cards ').find( '.shiftup' ).css( 'margin-top', '-' + cards_adjust + 'px' );
	}
	else {
		jQuery( '.divider + .cards ').find( '.shiftup' ).css( 'margin-top', '' );
	}

	/* Position the carousel */
	if( target_next.hasClass( 'shiftup-container' ) ) {
		if (Foundation.MediaQuery.atLeast('large')) {
			const carousel_adjust = Math.floor( target_prev_padding_bottom + ( target_svg_height * target_next_adjust_offset ) );
			jQuery( '.divider + .shiftup-container ').find( '.shiftup' ).css( 'margin-top', '-' + carousel_adjust + 'px' );
		}
		else {
			jQuery( '.divider + .shiftup-container ').find( '.shiftup' ).css( 'margin-top', '' );
		}
	}

}


/**
 * STYLE SVG
 * Gets the styles from the divder's siblings and sets the svg styling
 */
function tyk_divider_svg_styles( target_divider, target_sibling, target_sibling_pos ) {

	if ( target_sibling.hasClass( 'row--bg-image' ) ) {
		jQuery( target_divider + ' svg' ).addClass( target_sibling_pos + '--transparent' );
	}

	else {
		jQuery( target_divider + ' svg' ).addClass( target_sibling_pos + '--solid' );
		const divider_fill = jQuery( target_sibling ).css( 'background-color' );
		jQuery( target_divider + ' svg .divider__' + target_sibling_pos ).attr( 'fill', divider_fill );
	}
	
}




/**
 * INTERCHANGE
 * Load the following functions when interchange finishes loading the content
 */
jQuery( window ).on( 'load', function() {

	jQuery( '.divider' ).each(function() {
		/* Set up vars */
		let target_sibling = '';
		let target_sibling_pos = '';

		/* Get the divider we want to work with */
		const target_divider = '#' + jQuery( this ).attr( 'id' );
		
		/* The row _before_ the divder we are targeting */
		const target_prev = jQuery( target_divider ).prevAll( '.row' ).first();
		target_sibling = target_prev;
		target_sibling_pos = 'top';
		tyk_divider_svg_styles( target_divider, target_sibling, target_sibling_pos );

		/* The row _after_ the divder we are targeting */
		const target_next = jQuery( target_divider ).nextAll( '.row' ).first();
		target_sibling = target_next;
		target_sibling_pos = 'bottom';
		tyk_divider_svg_styles( target_divider, target_sibling, target_sibling_pos );

		/* The divider we are targeting */
		tyk_divider_svg_pos( target_divider );
	});

});


/**
 * WINDOW RESIZE
 * Resize the divider svg when viewport changes
 */
jQuery( window ).resize(function() {

	jQuery( '.divider' ).each(function() {

		const target_divider = '#' + jQuery( this ).attr( 'id' );
		tyk_divider_svg_pos( target_divider );

	});

});