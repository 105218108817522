/**
 * Force smooth scroll when interacting with anchor points withing same page
 */
jQuery('a[href*="#"]')
  // Remove links that don't actually link to anything
	.not('[href="#"]').not('[href="#0"]')
	.on('click', function(e) {
		// On-page links
    if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
    	var $target = jQuery(this.hash);
		let duration = 0.2 * $target.offset().top; // Depending on depth adjust speed ratio
    	$target = $target.length ? $target : jQuery('[name=' + this.hash.slice(1) + ']');
      
		if ($target.length) {
			e.preventDefault();
			
			jQuery('html, body').animate({
				scrollTop: $target.offset().top - 100
			}, { 
				duration: duration, 
				step: function(now,fx) {
					// Solution for lazy loading image reset offset
					const newOffset = $target.offset().top - 100;

					if(fx.end !== newOffset) {
						fx.end = newOffset;
					}
				}
			});
      	}
    }
});