/**
 * Functionality to make sidebar sticky. 
 * Sticky sidebar works with fixed and absolute positioning (css in block-content)
 */
var $window = jQuery(window);
var $stickySidebar = jQuery('.content__col--sticky');
var $stickySidebarInner = $stickySidebar.find('.sticky__inner');
var stickyClass = 'js-sticky';
var stickyBottomClass = 'js-sticky--bottom';
var $anchored_sections, $currentSection;
var sidebarTop, windowScrolled, sidebarEnd, sidebarOverflow;

function stuckSidebar() {
    $stickySidebar.removeClass(stickyBottomClass);
    $stickySidebar.addClass(stickyClass)
}

function stuckToBottomSidebar() {
    $stickySidebar.addClass(stickyBottomClass)
}

function releaseSidebar() {
    $stickySidebar.removeClass(stickyClass)
    $stickySidebar.removeClass(stickyBottomClass);
}

function checkScrollStatus() {
    sidebarEnd = $stickySidebar.height() + sidebarTop - $stickySidebarInner.height();

    if (windowScrolled > sidebarTop && windowScrolled > sidebarEnd) {
        stuckToBottomSidebar();
    } else if (windowScrolled > sidebarTop && windowScrolled < sidebarEnd) {
        stuckSidebar();
    } else {
        releaseSidebar();
    }
}

function highlightAnchor() {
    $anchored_sections.each(function() {
        var sectionPosition = jQuery(this).offset().top;
        
        if( sectionPosition < windowScrolled ){
            $currentSection = jQuery(this);
        }
        
        var id = $currentSection.attr('id');

        jQuery('.sticky__inner a').removeClass('js-active');
        jQuery('.sticky__inner a[href*="#' + id + '"]').addClass('js-active');
    })
  
}

if ( $stickySidebar.length ) {
    sidebarTop = $stickySidebar.offset().top;

    $anchored_sections = jQuery('.content__col1 [id]');
    $currentSection = jQuery($anchored_sections[0]);

    $window.on('scroll', function() {
        windowScrolled = $window.scrollTop() + 120;

        checkScrollStatus();
        highlightAnchor();
    });
}
