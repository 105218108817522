/* NAV: MAIN
 * Move location of nav in DOM by media query
 */
 function nav_relocate() {

	if (Foundation.MediaQuery.atLeast('xlarge')) {
		jQuery('#nav-main').appendTo('#nav-desktop');
	}

	else {
		jQuery('#nav-main').appendTo('#nav-mobile');
	}

 }

/* DOCUMENT LOAD
 * Load the following functions on document load
 */
 jQuery(document).ready(function() {
 	nav_relocate();
 });

/* MEDIA QUERY CHANGE
 * Watch for when the media query changes and then do somthing
 */
 jQuery(window).on('changed.zf.mediaquery', function() {
 	nav_relocate();
 });