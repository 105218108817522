jQuery(function($) {

    let $listingContainer, $filterContainer, $filter, $currentFilter, $cards, posttype, filterBy, filterValue, filterLoadMore;
    const filterIndexToHide = $window.outerWidth() < 1024 ? 5 : 7;

    $(window).on('load', function () {
        $filterContainer = $('.taxonomy-filter');

        if ($filterContainer.length) {
            initFilter();
        }
    });

    function scrollToViewCases() {
        const jsListingOffset = $listingContainer.offset().top;
        const headerHeight = $('#masthead').height();
    
        $('html, body').animate({
            scrollTop: jsListingOffset - headerHeight * 2
        }, 650);
    }

    function noCasesMessage(action) {
        $('.no-cards').remove();
    
        if (action === 'add') {
            $listingContainer.find('.row__inner').append('<h3 class="col no-cards" style="margin-top: 20%; margin-bottom: 10%; text-align: left; padding-left: 0;">Sorry, nothing was found for your search!!</h3>');
        }
    }

    function setFilteresToInital() {
        // Reset other filters value
        $filterContainer.find(`select:not([name=${filterBy}])`).val('all');
    }

    function filterCards() {
        const $filteredCards = $listingContainer.find(`.card[data-${filterBy}*=${filterValue}]`);
        const $filteredOut = $listingContainer.find(`.card:not([data-${filterBy}*=${filterValue}])`);
    
        if (filterValue === 'all') {
            $cards.show();
            filterLoadMore.hardToggleLoadMore('show');
            filterLoadMore.limitCards();
            noCasesMessage('remove');
            return;
        }

        filterLoadMore.hardToggleLoadMore('hide');
    
        if ($filteredCards.length) {
            $cards.show();
            $filteredOut.hide();
            noCasesMessage('remove');
        } else {
            $cards.hide();
            noCasesMessage('add');
        }
    }

    function filterActioned(e) {
        $currentFilter = $(e.currentTarget);
        filterBy = $currentFilter.attr('name');
        filterValue = $currentFilter.val();

        setFilteresToInital();
        filterCards();
        scrollToViewCases();
    }

    
    function initFilter() {
        $filter = $filterContainer.find('select');
        posttype = $filterContainer.data('posttype');
        $listingContainer = $(`.listing--filtered.${posttype}`);
        $cards = $listingContainer.find('.card');
        
        filterLoadMore = new loadMore($listingContainer, filterIndexToHide);
        filterLoadMore.showLoadMore();
        
        $filter.on('change', function(e) {
            filterActioned(e);
        });
    }




    /**
    * LOAD MORE
    */
    function loadMore($container, limit) {
        this.$container = $container;
        this.indexToHide = limit;
        this.loadMore = null;
        this.$loadMore = null;

        this.showLoadMore = () => {
            this.$container.find('.row__inner').append('<div style="text-align: center;"><br><br><span class="button button--secondary load-more">Load more</span></div>');
            this.$loadMore = this.$container.find('.load-more');

            this.limitCards();

            this.$container.find('.load-more').on('click', (e) => {
                this.indexToHide += $window.outerWidth() < 1024 ? 2 : 3;
                this.limitCards();
            });
        }

        this.limitCards = () => {
            this.$container.find(`.card:nth-child(-n+${this.indexToHide})`).show();
            this.$container.find(`.card:nth-child(n+${this.indexToHide})`).hide();
            
            this.toggleLoadMore();
        }

        this.toggleLoadMore = () => {
            const cardsNumber = this.$container.find('.card').length;

            if (cardsNumber > this.indexToHide - 1) {
                this.$loadMore.show();
            } else {
                this.$loadMore.hide();
            }
        }

        this.hardToggleLoadMore = (state) => {
            if (state == 'show') {
                this.$loadMore.show();
            } else {
                this.$loadMore.hide();
            }
        }
    }


    /**
     * Run load more for non filtered lists
     */

    const loadMoreListing = $(`.listing--loadmore`);
    const indexToHide = $window.outerWidth() < 1024 ? 5 : 7;

    if (loadMoreListing.length) {
        
        loadMoreListing.each((i, listing) => {
            const $listing = $(listing);
            if ($listing.find('.card').length > indexToHide) {
                $listingContainer = $listing;
                const newLoadMore = new loadMore($listing, indexToHide);
                newLoadMore.showLoadMore();
            } 
        });
    }
    
});