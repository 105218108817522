const autopopups = document.querySelectorAll('.automated-popup');
const body = document.querySelector('body');

class AutoPopup {
    constructor() {
        this.timeInit = Date.now();
        this.popup = null;
        this.popupTime = null;
        this.popupPerc = null;
        this.popupExpire = null;
        this.popupId = null;
        this.exitEventHandler = null;
        this.scrollEventHandler = null;
    }
    
    init(el) {
        this.popup = el;
        this.popupTime = this.popup.getAttribute('data-time');
        this.popupPerc = this.popup.getAttribute('data-percentage');
        this.popupExpire = parseInt(this.popup.getAttribute('data-expire'));
        this.popupId = this.popup.getAttribute('id');
        
        const isLargeScreen = Foundation.MediaQuery.atLeast('large');

        // Check if popup needs to be shown on exit or scroll
        if (this.popupTime !== null && isLargeScreen) {
            this.exitEventHandler = this.openOnExit.bind(this);
            body.addEventListener('mouseleave', this.exitEventHandler);
        } else {
            this.scrollEventHandler = this.openOnScroll.bind(this);
            document.addEventListener('scroll', this.scrollEventHandler);
        }
    }
    
    openOnExit() {
        // Exit intent, when user intents to leave the page
        const timeElapsed = Math.floor( (Date.now() - this.timeInit) / 1000 ); // Time elapsed in seconds
        
        if ( timeElapsed > this.popupTime && !this.isCookieSet() ) {
            body.removeEventListener('mouseleave', this.exitEventHandler);
            this.openPopup();
            this.pushDataLayer('exit');
        }
    }
    
    openOnScroll() {
        // Show when visitor scrolls up to X%
        const totalHeight = document.body.scrollHeight - window.innerHeight;
        const scrollPerc = totalHeight / 100 * (this.popupPerc || 80);

        if ( window.scrollY > scrollPerc && !this.isCookieSet() ) {
            document.removeEventListener('scroll', this.scrollEventHandler);
            this.openPopup();
            this.pushDataLayer('scroll');
        }
    }

    openPopup() {
        // Uses Foundation reveal object
        const newPopup = new Foundation.Reveal(jQuery(`#${this.popupId}`));
        newPopup.open();
        
        document.cookie = this.getCookie();
    }

    pushDataLayer(type) {
        window.dataLayer = window.dataLayer || [];
        dataLayer.push({'event' : 'auto_popup_opened', 'auto_popup_type' : type, 'auto_popup_content' : this.popupId});
    }

    getCookie() {
        // Sets cookie for this popup's id, expire date and page location
        const weekStamp = this.popupExpire * 24 * 60 * 60 * 1000;
        const expireStamp = Date.now() + weekStamp;
        const expireString = new Date(expireStamp).toUTCString();

        return `${this.popupId}=viewed; expires=${expireString}; path=/; SameSite=None; Secure`;
    }

    isCookieSet() {
        // Checks if a popup with same id has been shown
        return document.cookie.indexOf(`${this.popupId}=viewed`) > -1;
    }
}



if (autopopups.length) {
    // For multiple popups
    for(const popup of autopopups) {
        const ap = new AutoPopup();
        ap.init(popup)
    }
} 