// New pricing version - May 2021
// Switch functionality for pricing table
var $toggleSwitch = jQuery('.toggle-switch');

if ( $toggleSwitch ) {
    initToggleSwitch();
}

function initToggleSwitch() {
    var $switch_checkbox = $toggleSwitch.find('.switch input[type="checkbox"]');
    
    $switch_checkbox.on('change', function() {
        jQuery(this).closest('.toggle-switch').find('.toggle-switch__panel').toggleClass('is-active');
        $toggleSwitch.find('.switch').toggleClass('checked');
    });
    
}


// Custom tooltips
const info_tooltip = jQuery('.info-tooltip');

if (info_tooltip.length) {

    info_tooltip.on('click', function(e) {
        const icon = jQuery(e.currentTarget);
        const tooltip = icon.find('.info-tooltip__tooltip');
    
        jQuery('.info-tooltip__tooltip.active').removeClass('active');
        tooltip.toggleClass('active');
    });
    
    jQuery(body).on('click', function(e) {
        if (!e.target.closest('.info-tooltip')) {
            jQuery('.info-tooltip__tooltip.active').removeClass('active');
        }
    });

}


// Use url parameters to track when each table has been seen
// const currentUrl = window.location.href.split('#')[0];
// const initParam = window.location.search.split('=')[1];
// const priceSwitch = document.getElementById('pricingSwitcher');
// let currentTable = 'cloud';

// if (!initParam) {
//     window.history.replaceState(null, null, `?price=${currentTable}`);
// }

// window.history.replaceState(null, null, `?price=${currentTable}`);

// priceSwitch.addEventListener('change', function(e) {
//     currentTable = e.target.checked ? 'selfmanaged' : 'cloud';
//     window.history.replaceState(null, null, `?price=${currentTable}`);
// });