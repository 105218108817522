(function($) {

    const form_container = $(document).find('.hbspt-form');

    if (!form_container.length) return;

    const interval = setInterval(function() {
        const form = form_container.find('.hs-form');
        
        if (!form.length) return;
        
        addNotification();
        clearInterval(interval);
    }, 1000); // Wait for hubspot to inject the form to be able to select inside
    
    let countries_shown = false;
    
    function addNotification() {
        const region_field = $('[class*=hs_region');
        const notification = '<p style="margin-bottom: 0; line-height: 1rem;"><small style="font-size: 0.8rem;">We do not offer or sell our products and services in these locations:</small>&nbsp;<i class="info-tooltip info-tooltip--countries" style="position: relative;"><span class="info-tooltip--countries__countries" style="z-index: 20; position: absolute; top: 150%; right: -20px; width: 200px; font-size: 0.65rem; padding: 0.5rem; background-color: #36364C; color: white; border-radius: 8px; display: none;">Iran, Cuba, North Korea, Sudan, Syria, Russia, Crimea, Donetsk and Luhansk, Afghanistan, Libya</span></i></p>';

        if (!region_field.length) return;

        const region_container = region_field.find('.input');

        region_field.find('select').css('margin-bottom', '0');
        region_container.append(notification);

        const info_countries = $('.info-tooltip--countries');

        info_countries.on('mouseover mouseleave', e => {
            toggleCountriesInfo(e);
        });
    }

    function toggleCountriesInfo(e) {
        const countries = $(e.currentTarget).find('.info-tooltip--countries__countries');
        
        if (!countries_shown) {
            countries.css('display', 'block');
        } else {
            countries.css('display', 'none');
        }

        countries_shown = !countries_shown;
    }
    
  
})(jQuery)
