const $selectAnchor = jQuery('.select-anchor');

const selectAnchor = {
    value: '',

    init() {
        // Use select input as triger to scroll to anchor
        $selectAnchor.on('change', this.getAnchor.bind(this));
    },

    getAnchor(e) {
        this.value = e.currentTarget.value;
        this.goToAnchor();
    },

    goToAnchor() {
        if (this.value === 'all') return;
        
        const $target = jQuery(`#${this.value}`);
        var anchorOffset = $target.offset().top;
        var headerHeight = jQuery('#masthead').height();
        var duration = 0.2 * anchorOffset; // Depending on depth adjust speed ratio
        
        // Scroll to anchor with some offset
        jQuery('html, body').animate({
            scrollTop: anchorOffset - headerHeight
        }, { 
            duration: duration, 
            step: function(now,fx) {
                // Solution for lazy loading image reset offset
                const newOffset = $target.offset().top - headerHeight;

                if(fx.end !== newOffset) {
                    fx.end = newOffset;
                }
            }
        });
        
        $selectAnchor.val('all'); // Reset select to be able seleting any value

        // Set location hash to let user know where they are and allow copy anchored url
        setTimeout(() => {
            window.location.hash = this.value;
        }, 300);
    }
}

if ($selectAnchor) {
    selectAnchor.init();
}
